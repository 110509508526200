import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useState } from "react";
import { ReferenceInput, SelectInput } from "react-admin";
import MeasurementQuickCreateButton from "./MeasurementQuickCreateButton";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
  },
});

const MeasurementReferenceInput = (props) => {
  const classes = useStyles();
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    <div className={classes.root}>
      <ReferenceInput key={version} {...props}>
        <SelectInput
          optionText={(record) => `${record.month + 1}/${record.year}`}
        />
      </ReferenceInput>

      <MeasurementQuickCreateButton
        onChange={handleChange}
        organisation_id={props?.filter?.organisation_id}
      />
    </div>
  );
};

export default MeasurementReferenceInput;
