import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {
  ArrayInput,
  BooleanInput,
  Create,
  FormDataConsumer,
  maxValue,
  minValue,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
} from "react-admin";
import AutocompleteInput from "../../components/AutocompleteInput";
import useConfig from "../../context/config";
import { toSelectOptions } from "../../utils/transformers";
import EmployeeCommutesInput from "./EmployeeCommutesInput";
import MeasurementReferenceInput from "./MeasurementReferenceInput";
import OfficeReferenceInput from "./OfficeReferenceInput";
import VehicleUsageInput from "./VehicleUsageInput";

const customStyles = makeStyles({
  input: { display: "flex", width: 256, flexDirection: "column" },
});

const PERIODS = [
  { name: "Quarterly", id: "quarter" },
  { name: "Bi-Annually", id: "bi-annual" },
  { name: "Annually", id: "annual" },
];

const DISTANCE_METRICS = [
  { name: "Kilometres", id: "km" },
  { name: "Miles", id: "miles" },
];

const WASTE_METRICS = [
  { name: "kg", id: "kg" },
  { name: "Bin Bags", id: "bin_bags" },
];

export const WASTE_PERIODS = [
  { name: "Weekly", id: "week" },
  { name: "Monthly", id: "month" },
  ...PERIODS,
];
export const OfficeMeasurementCreate = (props) => {
  const config = useConfig();
  const classes = customStyles();

  return (
    <Create {...props}>
      <SimpleForm
        redirect="show"
        initialValues={{
          utilities: [
            { type: "gas" },
            { type: "electricity" },
            { type: "water" },
          ],
          waste: [{ type: "landfill" }, { type: "recycling" }],
        }}
      >
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <>
              <ReferenceInput
                source="details.organisation_id"
                reference="organisations"
                label="Organisation"
                className={classes.input}
                validate={required()}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              {formData?.details?.organisation_id && (
                <>
                  <MeasurementReferenceInput
                    source="measurement_id"
                    reference="measurements"
                    perPage={25}
                    className={classes.input}
                    validate={required()}
                    filter={{
                      organisation_id: formData?.details?.organisation_id,
                    }}
                  />
                  <OfficeReferenceInput
                    source="office_id"
                    reference="offices"
                    validate={required()}
                    className={classes.input}
                    filter={{
                      organisation_id: formData?.details?.organisation_id,
                    }}
                    perPage={25}
                  />
                  <NumberInput
                    source="details.number_of_staff"
                    label="Number of staff"
                    className={classes.input}
                    validate={[required(), minValue(0)]}
                    onWheel={(event) => event.target.blur()}
                  />
                  <NumberInput
                    source="details.staff_working_from_home_percentage"
                    label="Staff working from home percentage"
                    className={classes.input}
                    validate={[required(), minValue(0), maxValue(100)]}
                    onWheel={(event) => event.target.blur()}
                  />
                  <ArrayInput source="utilities">
                    <SimpleFormIterator disableAdd disableRemove>
                      <SelectInput
                        source="type"
                        label="Type"
                        disabled
                        validate={required()}
                        choices={[
                          { id: "gas", name: "Gas" },
                          { id: "electricity", name: "Electricity" },
                          { id: "water", name: "Water" },
                        ]}
                      />
                      <SelectInput
                        source="known"
                        label="Known"
                        validate={required()}
                        choices={[
                          { name: "Yes", id: "yes" },
                          { name: "No", id: "no" },
                          { name: "Not Used", id: "not_used" },
                        ]}
                      />
                      <NumberInput
                        source="value"
                        label="Value"
                        min={0}
                        validate={[required(), minValue(0)]}
                        onWheel={(event) => event.target.blur()}
                      />
                      <FormDataConsumer>
                        {({ scopedFormData, getSource }) => (
                          <SelectInput
                            source={getSource("unit")}
                            label="Unit"
                            validate={required()}
                            choices={[
                              ...(scopedFormData?.["type"] === "water"
                                ? [{ name: "m3", id: "m3" }]
                                : [{ name: "kWh", id: "kwh" }]),
                              { name: "GBP", id: "gbp" },
                            ]}
                          />
                        )}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                  <ArrayInput source="waste">
                    <SimpleFormIterator disableAdd disableRemove>
                      <SelectInput
                        source="type"
                        label="Type"
                        disabled
                        validate={required()}
                        choices={[
                          { name: "Landfill", id: "landfill" },
                          { name: "Recycling", id: "recycling" },
                        ]}
                      />
                      <SelectInput
                        source="known"
                        label="Known"
                        validate={required()}
                        choices={[
                          { name: "Yes", id: "yes" },
                          { name: "No", id: "no" },
                          { name: "Not Used", id: "not_used" },
                        ]}
                      />
                      <NumberInput
                        source="value"
                        label="Value"
                        validate={[required(), minValue(0)]}
                        onWheel={(event) => event.target.blur()}
                      />
                      <SelectInput
                        source="unit"
                        label="Unit"
                        choices={WASTE_METRICS}
                        validate={required()}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                  <EmployeeCommutesInput config={config} classes={classes} />
                  <VehicleUsageInput config={config} classes={classes} />

                  <ArrayInput source="business_travel">
                    <SimpleFormIterator>
                      <AutocompleteInput
                        source="to"
                        label="To"
                        validate={required()}
                      />
                      <AutocompleteInput
                        source="from"
                        label="From"
                        validate={required()}
                      />
                      {config && (
                        <SelectInput
                          source="mode"
                          label="Mode"
                          validate={required()}
                          choices={toSelectOptions(
                            config?.businessTravelModesOfTransport
                          )}
                        />
                      )}
                      <NumberInput
                        source="passengers"
                        label="Passengers"
                        validate={[required(), minValue(0)]}
                        onWheel={(event) => event.target.blur()}
                      />
                      <BooleanInput source="round_trip" label="Round Trip?" />
                    </SimpleFormIterator>
                  </ArrayInput>
                </>
              )}
            </>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
