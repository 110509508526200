import * as React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { SaveButton, SimpleForm, Toolbar, showNotification } from "react-admin"; // eslint-disable-line import/no-unresolved
import Button from "@material-ui/core/Button";
import S3Uploader from "../../components/S3Uploader";
import ImagesTable from "./ReductionPartnerImageTable";
import { useState, useEffect } from "react";
import axios from "axios";
import { useRefresh } from "react-admin";

// We need a custom toolbar to add our custom buttons
// The CancelButton allows to close the modal without submitting anything
const ReductionPartnerImageUploadToolbar = ({
  submitting,
  onCancel,
  ...props
}) => (
  <Toolbar {...props} disableGutters>
    <SaveButton />
    <Button onClick={onCancel} />
  </Toolbar>
);

ReductionPartnerImageUploadToolbar.propTypes = {
  submitting: PropTypes.bool,
  onCancel: PropTypes.func.isRequired
};

const useStyles = makeStyles({
  form: { padding: 0 }
});

const ReductionPartnerImageUpload = ({ onCancel, onSave, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const [positions, setPositions] = useState([]);
  const submitting = useSelector(state => state.admin.loading > 0);
  const record = useSelector(
    state =>
      state.admin.resources[props.resource]
        ? state.admin.resources[props.resource].data[props.id]
        : null,
    [props.resource, props.id]
  );

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");

      await axios.patch(
        `${process.env.REACT_APP_HOST}/reduction-partners-images`,
        positions,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      refresh();
      onSave();
    } catch (error) {
      dispatch(showNotification(error.message, "error"));
    }
  };

  const onDrop = React.useCallback(async acceptedFiles => {
    const token = localStorage.getItem("token");
    try {
      acceptedFiles.forEach(async file => {
        const { data } = await axios.get(
          `${process.env.REACT_APP_HOST}/reduction-partners-images/sign`,
          {
            params: {
              filename: `reduction-products/${props.id}/${file.name}`,
              filetype: file.type
            },
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        const signedUrl = data.url;

        const options = {
          headers: {
            "Content-Type": file.type
          }
        };
        await axios.put(signedUrl, file, options);
        await axios.post(
          `${process.env.REACT_APP_HOST}/reduction-partners-images`,
          {
            url: signedUrl.split("?")[0],
            reduction_partner_id: parseInt(props.id)
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        refresh();
      });
    } catch (error) {
      showNotification(error.toString());
    }
  }, []);

  return (
    <SimpleForm
      save={handleSave}
      saving={submitting}
      redirect={false}
      toolbar={
        <ReductionPartnerImageUploadToolbar
          onCancel={onCancel}
          submitting={submitting}
        />
      }
      classes={{ form: classes.form }}
      {...props}
    >
      <ImagesTable
        images={record.images}
        id={props.id}
        positions={positions}
        setPositions={setPositions}
      />
      <S3Uploader
        resource="reduction-partners-images"
        folder="reduction-products"
        id={props.id}
        onDrop={onDrop}
      />
    </SimpleForm>
  );
};

export default ReductionPartnerImageUpload;
