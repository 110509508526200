import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import CalculateIcon from "@mui/icons-material/Calculate";
import Axios from "axios";
import React from "react";
import {
  BooleanField,
  Button,
  Datagrid,
  EditButton,
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useRedirect,
  useRefresh,
} from "react-admin";
import { Link } from "react-router-dom";

const AddNewMeasurementResultButton = ({ record }) => (
  <Button
    component={Link}
    to={`/measurement-results/create?measurement_id=${record.id}`}
    label="Add a Result"
  >
    <ChatBubbleIcon />
  </Button>
);

const EditMeasurementResultButton = ({ record }) => (
  <Button
    component={Link}
    to={`/measurement-results/${record.measurement_result.id}/edit?office_measurement_id=${record?.id}`}
    label=" Edit Result"
  >
    <ChatBubbleIcon />
  </Button>
);

const CalculateResultsButton = ({ record }) => {
  const redirect = useRedirect();
  const refresh = useRefresh();

  const calculateResults = async ({ id, measurement_id }) => {
    const token = localStorage.getItem("token");

    const { data } = await Axios.post(
      `${process.env.REACT_APP_HOST}/measurement-results/calculate`,
      { id, measurement_id },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    redirect(`/office-measurements/${id}/show/result`);

    refresh();
  };

  return (
    <Button
      label={
        record?.measurement_result ? "Recalculate Results" : "Calculate Results"
      }
      onClick={async () => await calculateResults(record)}
    >
      <CalculateIcon />
    </Button>
  );
};

const PostShowActions = ({ basePath, data }) => {
  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
      <CalculateResultsButton record={data} />

      {data?.measurement_result && (
        <EditMeasurementResultButton record={data} />
      )}
    </TopToolbar>
  );
};

export const OfficeMeasurementShow = (props) => {
  return (
    <>
      <Show actions={<PostShowActions />} {...props}>
        <TabbedShowLayout>
          <Tab label="Summary">
            <TextField source="id" />

            <ReferenceField
              source="details.organisation_id"
              reference="organisations"
              label="Organisation"
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="measurement_id" reference="measurements">
              <FunctionField
                label="Name"
                render={(record) => `${record.month + 1}/${record.year}`}
              />
            </ReferenceField>
            <ReferenceField source="office_id" reference="offices">
              <TextField source="name" />
            </ReferenceField>
            <TextField source="details.country" label="Country code" />
            <BooleanField source="details.fully_remote" label="Fully Remote" />
            <FunctionField
              label="Size"
              render={(record) =>
                `${record.details.size}${record.details.unit}`
              }
            />
            <NumberField
              source="details.number_of_staff"
              label="Number of staff"
            />
            <NumberField
              source="details.staff_working_from_home_percentage"
              label="Staff working from home percentage"
            />

            <ReferenceArrayField reference="utilities" source="utilities_ids">
              <Datagrid>
                <TextField source="id" />
                <TextField source="type" />
                <NumberField source="value" />
                <TextField source="unit" />
                <TextField source="known" />
              </Datagrid>
            </ReferenceArrayField>

            <ReferenceArrayField reference="waste" source="waste_ids">
              <Datagrid>
                <TextField source="id" />
                <TextField source="type" />
                <NumberField source="value" />
                <TextField source="unit" />
                <TextField source="known" />
              </Datagrid>
            </ReferenceArrayField>

            <ReferenceArrayField
              reference="employee-commutes"
              source="employee_commutes_ids"
              label="Employee Commutes"
            >
              <Datagrid>
                <TextField source="id" />
                <TextField source="name" />
                <NumberField source="value" />
                <TextField source="mode" />
                <TextField source="unit" />
              </Datagrid>
            </ReferenceArrayField>
            <ReferenceArrayField
              reference="vehicle-usage"
              source="vehicle_usage_ids"
              label="Vehicle Usage"
            >
              <Datagrid>
                <TextField source="id" />
                <TextField source="registration_number" />
                <NumberField source="value" />
                <TextField source="mode" />
                <TextField source="unit" />
              </Datagrid>
            </ReferenceArrayField>

            <ReferenceArrayField
              reference="business-travel"
              source="business_travel_ids"
              label="Business Travel"
            >
              <Datagrid>
                <TextField source="id" />
                <TextField source="from" />
                <TextField source="to" />
                <TextField source="mode" />
                <NumberField source="distance" />
                <NumberField source="passengers" />
                <BooleanField source="round_trip" />
                <TextField source="departure_lat" />
                <TextField source="departure_lng" />
                <TextField source="destination_lat" />
                <TextField source="destination_lng" />
              </Datagrid>
            </ReferenceArrayField>
          </Tab>

          <Tab label="Result" path="result">
            <TextField source="measurement_result.scope_1" label="Scope 1" />
            <TextField source="measurement_result.scope_2" label="Scope 2" />
            <TextField source="measurement_result.scope_3" label="Scope 3" />
            <TextField source="measurement_result.gas" label="Gas" />
            <TextField
              source="measurement_result.electricity"
              label="Electricity"
            />
            <TextField
              source="measurement_result.water_supply"
              label="Water Supply"
            />
            <TextField
              source="measurement_result.water_treated"
              label="Water Treated"
            />

            <TextField
              source="measurement_result.business_travel"
              label="Business Travel"
            />
            <TextField
              source="measurement_result.waste_landfill"
              label="Waste Landfill"
            />
            <TextField
              source="measurement_result.waste_recycling"
              label="Waste Recycling"
            />
            <TextField
              source="measurement_result.employee_commutes"
              label="Employee Commutes"
            />

            <TextField
              source="measurement_result.homeworking"
              label="Homeworking"
            />

            <TextField
              source="measurement_result.vehicle_usage"
              label="Company Vehicle Usage"
            />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};
