import { format } from "date-fns";
import React from "react";
import {
  Edit,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
} from "react-admin";

export const MeasurementEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <SelectInput
          validate={required()}
          source="month"
          fullWidth
          choices={[...Array(12).keys()].map((key) => ({
            id: key,
            name: format(new Date(2021, key), "LLLL"),
          }))}
        />
        <NumberInput fullWidth source="year" validate={required()} />

        <NumberInput
          fullWidth
          source="time_period"
          label="Time Period (No. of months)"
          validate={required()}
        />
        <ReferenceInput
          source="organisation_id"
          reference="organisations"
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
