import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import ReductionStrategyPDFUpload from "./ReductionStrategyPDFUpload";

export const ReductionStrategyEdit = (props) => {
  const [reductionTags, setReductionTags] = useState([]);
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  const getReductionTags = async () => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.get(
        `${process.env.REACT_APP_HOST}/reduction-strategies`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setReductionTags(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getReductionTags();
  }, []);

  const handleNewClick = useCallback(
    (event) => {
      event.preventDefault();
      setShowCreateDialog(true);
    },
    [setShowCreateDialog]
  );

  const handleCloseCreate = useCallback(() => {
    setShowCreateDialog(false);
  }, [setShowCreateDialog]);

  return (
    <>
      <Edit {...props}>
        <SimpleForm>
          <TextInput source="name" />
          <ReferenceInput source="organisation_id" reference="organisations">
            <SelectInput optionText="name" />
          </ReferenceInput>

          <Button data-testid="button-add-post" onClick={handleNewClick}>
            View PDF
          </Button>
        </SimpleForm>
      </Edit>
      <Dialog
        data-testid="dialog-add-post"
        fullWidth
        open={showCreateDialog}
        onClose={handleCloseCreate}
      >
        <DialogTitle>Upload Images</DialogTitle>
        <DialogContent>
          <ReductionStrategyPDFUpload
            onCancel={handleCloseCreate}
            onSave={handleCloseCreate}
            id={props.id}
            resource={props.resource}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
