import React from "react";
import {
  BooleanInput,
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

const InternalUserCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <TextInput source="email" />
      <TextInput source="password" type="password" />
      <ReferenceInput source="organisation_id" reference="organisations">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="terms_accepted" />
      <BooleanInput source="is_admin" />
      <BooleanInput source="password_change_required" />
    </SimpleForm>
  </Create>
);

export default InternalUserCreate;
