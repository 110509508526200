import { parse } from "query-string";
import React from "react";
import {
  Create,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
} from "react-admin";

export const MeasurementResultCreate = (props) => {
  const { measurement_id: measurement_id_string } = parse(
    props.location.search
  );

  // ra-data-fakerest uses integers as identifiers, we need to parse the querystring
  // We also must ensure we can still create a new comment without having a measurement_id
  // from the url by returning an empty string if measurement_id isn't specified
  const measurement_id = measurement_id_string
    ? parseInt(measurement_id_string, 10)
    : "";

  const redirect = measurement_id
    ? `/measurements/${measurement_id}/show/result`
    : false;

  return (
    <Create {...props}>
      <SimpleForm defaultValue={{ measurement_id }} redirect={redirect}>
        <ReferenceInput
          source="measurement_id"
          reference="measurements"
          allowEmpty
          validate={required()}
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
        <NumberInput source="gas" label="Gas" />
        <NumberInput source="electricity" label="Electricity" />
        <NumberInput source="water_supply" label="Water Supply" />
        <NumberInput source="water_treated" label="Water Treated" />
        <NumberInput source="business_travel" label="Business Travel" />
        <NumberInput source="waste_landfill" label="Waste Landfill" />
        <NumberInput source="waste_recycling" label="Waste Recycling" />
        <NumberInput source="employee_commutes" label="Employee Commutes" />
        <NumberInput source="homeworking_gas" label="Homeworking Gas" />
        <NumberInput source="vehicle_usage" label="Company Vehicle Usage" />

        <NumberInput
          source="homeworking_electricity"
          label="Homeworking Electricity"
        />
      </SimpleForm>
    </Create>
  );
};

export default MeasurementResultCreate;
