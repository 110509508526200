import jsonServerProvider from "ra-data-json-server";
import React from "react";
import { Admin, fetchUtils, Resource } from "react-admin";
import "./App.css";
import { ConfigProvider } from "./context/config";
import {
  InterestCreate,
  InterestEdit,
  InterestList,
  InterestShow,
} from "./scenes/interests";
import {
  InternalUserCreate,
  InternalUserEdit,
  InternalUserList,
  InternalUserShow,
} from "./scenes/internalUsers";
import {
  MeasurementResultCreate,
  MeasurementResultEdit,
} from "./scenes/measurementResults";
import {
  MeasurementEdit,
  MeasurementList,
  MeasurementShow,
} from "./scenes/measurements";
import {
  OfficeMeasurementCreate,
  OfficeMeasurementEdit,
  OfficeMeasurementList,
  OfficeMeasurementShow,
} from "./scenes/officeMeasurements";
import { OfficeEdit, OfficeList } from "./scenes/offices";
import {
  OrganisationCreate,
  OrganisationEdit,
  OrganisationList,
  OrganisationShow,
} from "./scenes/organisations";
import { PolicyCreate, PolicyEdit, PolicyList } from "./scenes/policies";
import {
  PolicyCategoryCreate,
  PolicyCategoryEdit,
  PolicyCategoryList,
} from "./scenes/policyCategories";
import {
  ProjectCreate,
  ProjectEdit,
  ProjectList,
  ProjectShow,
} from "./scenes/projects";
import {
  ReductionCategoryCreate,
  ReductionCategoryEdit,
  ReductionCategoryList,
  ReductionCategoryShow,
} from "./scenes/reductionCategories";
import {
  ReductionPartnerCreate,
  ReductionPartnerEdit,
  ReductionPartnerList,
  ReductionPartnerShow,
} from "./scenes/reductionPartners";
import {
  ReductionStrategyCreate,
  ReductionStrategyEdit,
  ReductionStrategyList,
  ReductionStrategyShow,
} from "./scenes/reductionStrategies";
import {
  ReductionTagCreate,
  ReductionTagEdit,
  ReductionTagList,
  ReductionTagShow,
} from "./scenes/reductionTags";
import { RoleCreate, RoleEdit, RoleList } from "./scenes/roles";
import { UserCreate, UserEdit, UserList, UserShow } from "./scenes/users";
import authProvider from "./utils/authProvider";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(process.env.REACT_APP_HOST, httpClient);

const App = () => (
  <ConfigProvider>
    <Admin dataProvider={dataProvider} authProvider={authProvider}>
      <Resource
        name="interests"
        list={InterestList}
        edit={InterestEdit}
        create={InterestCreate}
        show={InterestShow}
      />
      <Resource
        name="internal-users"
        list={InternalUserList}
        edit={InternalUserEdit}
        create={InternalUserCreate}
        show={InternalUserShow}
      />
      <Resource
        name="organisations"
        list={OrganisationList}
        edit={OrganisationEdit}
        create={OrganisationCreate}
        show={OrganisationShow}
      />

      <Resource
        name="measurements"
        list={MeasurementList}
        show={MeasurementShow}
        edit={MeasurementEdit}
      />
      <Resource
        name="office-measurements"
        list={OfficeMeasurementList}
        show={OfficeMeasurementShow}
        create={OfficeMeasurementCreate}
        edit={OfficeMeasurementEdit}
      />
      <Resource name="offices" list={OfficeList} edit={OfficeEdit} />
      <Resource
        name="measurement-results"
        create={MeasurementResultCreate}
        edit={MeasurementResultEdit}
      />
      <Resource
        name="projects"
        list={ProjectList}
        create={ProjectCreate}
        edit={ProjectEdit}
        show={ProjectShow}
      />
      <Resource
        name="policy-categories"
        list={PolicyCategoryList}
        create={PolicyCategoryCreate}
        edit={PolicyCategoryEdit}
      />
      <Resource
        name="policies"
        list={PolicyList}
        create={PolicyCreate}
        edit={PolicyEdit}
      />

      <Resource
        name="reduction-categories"
        list={ReductionCategoryList}
        edit={ReductionCategoryEdit}
        create={ReductionCategoryCreate}
        show={ReductionCategoryShow}
      />
      <Resource
        name="reduction-partners"
        list={ReductionPartnerList}
        edit={ReductionPartnerEdit}
        create={ReductionPartnerCreate}
        show={ReductionPartnerShow}
      />
      <Resource
        name="reduction-tags"
        list={ReductionTagList}
        edit={ReductionTagEdit}
        create={ReductionTagCreate}
        show={ReductionTagShow}
      />
      <Resource
        name="reduction-strategies"
        list={ReductionStrategyList}
        edit={ReductionStrategyEdit}
        create={ReductionStrategyCreate}
        show={ReductionStrategyShow}
      />
      <Resource
        name="roles"
        list={RoleList}
        edit={RoleEdit}
        create={RoleCreate}
      />
      <Resource
        name="users"
        list={UserList}
        edit={UserEdit}
        create={UserCreate}
        show={UserShow}
      />
      <Resource name="business-travel" />
      <Resource name="expenses" />
      <Resource name="employee-commutes" />
      <Resource name="utilities" />
      <Resource name="vehicle-usage" />
      <Resource name="vehicles" />
      <Resource name="waste" />
    </Admin>
  </ConfigProvider>
);

export default App;
