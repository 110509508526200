import React from "react";
import {
  Edit,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { useSelector } from "react-redux";
import useConfig from "../../context/config";
import { toSelectOptions } from "../../utils/transformers";

export const OrganisationEdit = (props) => {
  const config = useConfig();
  const record = useSelector(
    (state) =>
      state.admin.resources[props.resource]
        ? state.admin.resources[props.resource].data[props.id]
        : null,
    [props.resource, props.id]
  );

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        {config && (
          <SelectInput
            source="industry"
            label="Industry"
            validate={required()}
            choices={toSelectOptions(config?.organisationTypes)}
          />
        )}
        <ReferenceInput
          source="owner_id"
          reference="users"
          filter={{ organisation_id: record?.id }}
        >
          <SelectInput optionText="email" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
