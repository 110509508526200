import React from "react";
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

const measurementFilters = [
  <TextInput source="year" alwaysOn />,
  <ReferenceInput source="organisation_id" reference="organisations" alwaysOn>
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

export const MeasurementList = (props) => (
  <List {...props} filters={measurementFilters}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField source="organisation_id" reference="organisations">
        <TextField source="name" />
      </ReferenceField>
      <FunctionField
        label="Time"
        render={(record) => `${record.month + 1}/${record.year}`}
        sortBy={(record) => `${record.month + 1}/${record.year}`}
      />
      <NumberField source="time_period" label="Time Period (No. of months)" />
    
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);
