import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";

export const ReductionTagEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="label" />
    </SimpleForm>
  </Edit>
);
