import React from "react";
import {
  BooleanInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";

const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <TextField source="id" />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <TextInput source="email" />

      <ReferenceInput source="organisation_id" reference="organisations">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="terms_accepted" />
      <BooleanInput source="is_admin" />
      <BooleanInput source="password_change_required" />
    </SimpleForm>
  </Edit>
);

export default UserEdit;
