import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  ReferenceField,
  ImageField
} from "react-admin";

export const ReductionPartnerList = props => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="slug" />
      <TextField source="link" />
      <TextField source="discount_code" />
      <TextField source="discount" />
      <TextField source="description" />
      <TextField source="benefits" />
      <NumberField source="price" />
      <TextField source="price_suffix" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <ReferenceField
        source="reduction_category_id"
        reference="reduction-categories"
      >
        <TextField source="label" />
      </ReferenceField>
    </Datagrid>
  </List>
);
