import React, { useCallback, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useRefresh } from "react-admin";
import { showNotification } from "react-admin";
import SVG from "react-inlinesvg";

const useStyles = makeStyles({
  image: { maxWidth: "200px" },
  icon: {
    width: "3rem",
    height: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FBD365",
    borderRadius: 5
  },
  container: { width: "100%" }
});

const ImagesTable = ({ images, id }) => {
  const refresh = useRefresh();
  const classes = useStyles();

  const onDelete = useCallback(async id => {
    const token = localStorage.getItem("token");
    try {
      await axios.patch(
        `${process.env.REACT_APP_HOST}/policy-categories/${id}`,
        {
          icon: null
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      refresh();
    } catch (error) {
      showNotification(error.toString());
    }
  }, []);

  return (
    <div className={classes.container}>
      <table>
        <thead>
          <tr>
            <th width="75%">Image</th>
          </tr>
        </thead>
        <tbody>
          {images && (
            <tr key={images}>
              <td>
                <div className={classes.icon}>
                  <SVG
                    src={images}
                    width={24}
                    style={{ fill: "#4E4E4E" }}
                    height="auto"
                    title="Menu"
                  />
                </div>
              </td>
              <td>
                <Button
                  onClick={() => {
                    onDelete(id);
                  }}
                >
                  delete
                </Button>{" "}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ImagesTable;
