import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  NumberField,
  ReferenceField,
  ImageField,
  ArrayField,
  SingleFieldList,
  ChipField
} from "react-admin";

export const ReductionPartnerShow = props => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="slug" />
        <TextField source="link" />
        <TextField source="discount_code" />
        <TextField source="discount" />
        <TextField source="description" />
        <TextField source="benefits" />
        <NumberField source="price" />
        <TextField source="price_suffix" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <ReferenceField
          source="reduction_category_id"
          reference="reduction-categories"
        >
          <TextField source="label" />
        </ReferenceField>

        <ArrayField source="reduction_tags">
          <SingleFieldList>
            <ChipField source="label" />
          </SingleFieldList>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
