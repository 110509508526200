import React from "react";
import { Show, SimpleShowLayout, TextField, DateField } from "react-admin";

export const ReductionStrategyShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="pdf_url" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);
