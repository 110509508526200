import React from "react";
import {
  List,
  Datagrid,
  TextField,
  RichTextField,
  UrlField,
  DateField
} from "react-admin";

const ProjectList = props => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="price_id" label="Price Id" />
      <TextField source="short_description" />
      <RichTextField source="long_description" />
      <RichTextField source="further_description" />
      <UrlField source="image" />
      <TextField source="sustainability_development_goals" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <TextField source="slug" />
    </Datagrid>
  </List>
);

export default ProjectList;
