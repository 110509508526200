import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { SaveButton, SimpleForm, Toolbar, showNotification } from "react-admin"; // eslint-disable-line import/no-unresolved
import Button from "@material-ui/core/Button";
import S3Uploader from "../../components/S3Uploader";
import ImagesTable from "./ReductionCategoryImageTable";
import axios from "axios";
import { useRefresh } from "react-admin";

// We need a custom toolbar to add our custom buttons
// The CancelButton allows to close the modal without submitting anything
const ReductionCategoryImageUploadToolbar = ({
  submitting,
  onCancel,
  ...props
}) => (
  <Toolbar {...props} disableGutters>
    <SaveButton />
    <Button onClick={onCancel} />
  </Toolbar>
);

const useStyles = makeStyles({
  form: { padding: 0 }
});

const ReductionCategoryImageUpload = ({ onCancel, onSave, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const submitting = useSelector(state => state.admin.loading > 0);
  const record = useSelector(
    state =>
      state.admin.resources[props.resource]
        ? state.admin.resources[props.resource].data[props.id]
        : null,
    [props.resource, props.id]
  );

  const handleSave = async () => {
    try {
      onSave();
    } catch (error) {
      dispatch(showNotification(error.message, "error"));
    }
  };

  const onDrop = React.useCallback(async acceptedFiles => {
    const token = localStorage.getItem("token");
    try {
      if (acceptedFiles.length > 1) {
        throw Error("too many files");
      }
      acceptedFiles.forEach(async file => {
        const { data } = await axios.get(
          `${process.env.REACT_APP_HOST}/reduction-partners-images/sign`,
          {
            params: {
              filename: `reduction-categories/${props.id}/icon`,
              filetype: file.type
            },
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        const signedUrl = data.url;

        const options = {
          headers: {
            "Content-Type": file.type
          }
        };
        await axios.put(signedUrl, file, options);
        await axios.patch(
          `${process.env.REACT_APP_HOST}/reduction-categories/${props.id}`,
          {
            icon: signedUrl.split("?")[0]
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        refresh();
      });
    } catch (error) {
      showNotification(error.toString());
    }
  }, []);

  return (
    <SimpleForm
      save={handleSave}
      saving={submitting}
      redirect={false}
      toolbar={
        <ReductionCategoryImageUploadToolbar
          onCancel={onCancel}
          submitting={submitting}
        />
      }
      classes={{ form: classes.form }}
      {...props}
    >
      <ImagesTable images={record.icon} id={props.id} />
      {!record.icon && <S3Uploader id={props.id} onDrop={onDrop} />}
    </SimpleForm>
  );
};

export default ReductionCategoryImageUpload;
