import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Papa from "papaparse";
import React, { useState } from "react";
import {
  ArrayInput,
  Button,
  minValue,
  NumberInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  useNotify,
} from "react-admin";
import { useForm } from "react-final-form";
import { toSelectOptions } from "../../utils/transformers";

const DISTANCE_METRICS = [
  { name: "Kilometres", id: "km" },
  { name: "Miles", id: "miles" },
];
const EmployeeCommutesInput = ({ config, classes }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const form = useForm();

  return (
    <>
      <Button
        label="Upload Employee Commutes (Experimental)"
        onClick={handleClick}
      />
      <ArrayInput source="employee_commutes">
        <SimpleFormIterator>
          <TextInput source="name" label="Name" validate={required()} />
          {config && (
            <SelectInput
              source="mode"
              label="Mode"
              validate={required()}
              choices={toSelectOptions(
                config?.employeeCommutesModesOfTransport
              )}
            />
          )}
          <NumberInput
            source="value"
            label="Value"
            validate={[required(), minValue(0)]}
            onWheel={(event) => event.target.blur()}
          />

          <SelectInput
            source="unit"
            label="Unit"
            validate={required()}
            choices={DISTANCE_METRICS}
          />
        </SimpleFormIterator>
      </ArrayInput>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create Measurement"
      >
        <DialogTitle>Upload Employee Commutes CSV</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <div>
              <input
                type="file"
                accept=".csv"
                onChange={(e) => {
                  const files = e.target.files;
                  if (files) {
                    setLoading(true);
                    Papa.parse(files[0], {
                      header: true,
                      complete: function (results) {
                        results.data.forEach((commute, index) =>
                          form.change(`employee_commutes[${index}]`, {
                            name: commute.name,
                            unit: DISTANCE_METRICS.find(
                              (metric) => metric.id === commute.unit
                            )?.id,
                            value: parseFloat(commute.value),
                            mode: toSelectOptions(
                              config?.employeeCommutesModesOfTransport
                            ).find((mode) => mode.name === commute.mode)?.id,
                          })
                        );
                        handleCloseClick();
                      },
                    });
                    setLoading(false);
                  }
                }}
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EmployeeCommutesInput;
