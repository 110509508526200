import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import {
  BooleanInput,
  Button,
  FormWithRedirect,
  minValue,
  NumberInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useCreate,
  useNotify,
} from "react-admin";
import { useForm } from "react-final-form";
import { toSelectOptions } from "../../utils/transformers";

function OfficeQuickCreateButton({ onChange, organisation_id }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate("offices");
  const notify = useNotify();
  const form = useForm();
  const [countries, setCountries] = useState([]);

  const getCountries = async () => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await Axios.get(
        `${process.env.REACT_APP_HOST}/places/country-codes`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCountries(data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: { ...values, size: parseInt(values.size) } } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          // Update the comment form to target the newly created Office
          // Updating the ReferenceInput value will force it to reload the available Offices
          form.change("office_id", data.id);
          onChange();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <>
      <Button onClick={handleClick} label="ra.action.create">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create Office"
      >
        <DialogTitle>Create Office</DialogTitle>

        <FormWithRedirect
          resource="offices"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <TextInput source="name" validate={required()} fullWidth />
                <SelectInput
                  source="country"
                  choices={toSelectOptions(countries)}
                />
                <NumberInput
                  source="size"
                  type="number"
                  validate={[required(), minValue(0)]}
                  fullWidth
                />
                <SelectInput
                  source="unit"
                  choices={[
                    { name: "m²", id: "m2" },
                    { name: "ft²", id: "sqft" },
                  ]}
                />
                <BooleanInput source="fully_remote" />
                <ReferenceInput
                  source="organisation_id"
                  reference="organisations"
                  initialValue={organisation_id}
                  disabled={organisation_id}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default OfficeQuickCreateButton;
