import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {
  Datagrid,
  DateField,
  ImageField,
  List,
  NumberField,
  TextField,
} from "react-admin";

const useStyles = makeStyles((theme) => ({
  image: {
    width: 50,
    height: 50,
  },
}));

export const OrganisationList = (props) => {
  const classes = useStyles();

  return (
    <List {...props}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" />
        <ImageField source="logo" className={classes} />
        <NumberField source="max_users_count" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <DateField source="deleted_at" />
      </Datagrid>
    </List>
  );
};
