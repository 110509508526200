import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import { format } from "date-fns";
import React, { useState } from "react";
import {
  Button,
  FormWithRedirect,
  NumberInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  useCreate,
  useNotify,
} from "react-admin";
import { useForm } from "react-final-form";

function OfficeQuickCreateButton({ onChange, organisation_id }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate("measurements");
  const notify = useNotify();
  const form = useForm();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          // Update the comment form to target the newly created Office
          // Updating the ReferenceInput value will force it to reload the available measurements
          form.change("measurement_id", data.id);
          onChange();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="ra.action.create">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create Measurement"
      >
        <DialogTitle>Create Measurement</DialogTitle>

        <FormWithRedirect
          resource="measurements"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <SelectInput
                  validate={required()}
                  source="month"
                  fullWidth
                  choices={[...Array(12).keys()].map((key) => ({
                    id: key,
                    name: format(new Date(2021, key), "LLLL"),
                  }))}
                />
                <NumberInput fullWidth source="year" validate={required()} />

                <NumberInput
                  fullWidth
                  source="time_period"
                  label="Time Period (No. of months)"
                  validate={required()}
                />
                <ReferenceInput
                  source="organisation_id"
                  reference="organisations"
                  validate={required()}
                  initialValue={organisation_id}
                  disabled={organisation_id}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default OfficeQuickCreateButton;
