import DashboardIcon from "@mui/icons-material/Dashboard";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import axios from "axios";
import React from "react";
import {
  BooleanField,
  Button,
  DateField,
  EditButton,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRefresh,
} from "react-admin";

const ViewDashboardButton = ({ onClick }) => {
  return (
    <Button label="View Dashboard" onClick={onClick}>
      <DashboardIcon />
    </Button>
  );
};

const CompleteOnboardingButton = ({ onClick }) => {
  return (
    <Button label="Complete Onboarding" onClick={onClick}>
      <DomainVerificationIcon />
    </Button>
  );
};

const PostShowActions = ({ basePath, data, resource }) => {
  const refresh = useRefresh();

  const sendWelcomeEmail = async () => {
    await axios.post(
      `${process.env.REACT_APP_HOST}/organisations/${data.id}/new-login-link`,
      {},
      { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
    );
  };

  const openDashboard = async () => {
    const { data: token } = await axios.post(
      `${process.env.REACT_APP_HOST}/organisations/${data.id}/dashboard-token`,
      {},
      { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
    );

    window.open(token);
  };

  const handleCompleteOnboarding = async () => {
    await axios.post(
      `${process.env.REACT_APP_HOST}/organisations/${data.id}/onboard`,
      { onboarded: true },
      { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
    );

    refresh();
  };

  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
      <ViewDashboardButton onClick={openDashboard} />
      {!data?.onboarded && (
        <CompleteOnboardingButton onClick={handleCompleteOnboarding} />
      )}
    </TopToolbar>
  );
};

export const OrganisationShow = (props) => {
  return (
    <>
      <Show actions={<PostShowActions />} {...props}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="industry" />
          <BooleanField source="onboarded" />
          <ReferenceField source="owner_id" label="owner" reference="users">
            <TextField source="email" />
          </ReferenceField>
          <DateField source="created_at" />
          <DateField source="updated_at" />
          <DateField source="deleted_at" />
        </SimpleShowLayout>
      </Show>
    </>
  );
};
