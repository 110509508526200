import React, { useCallback, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useRefresh } from "react-admin";
import { showNotification } from "react-admin";
import Select from "react-select";

const useStyles = makeStyles({
  image: { maxWidth: "200px" },
  container: { width: "100%" }
});

const ImagesTable = ({ images, id, setPositions, positions }) => {
  const refresh = useRefresh();
  const [currentPositions, setCurrentPositions] = useState([]);

  const onDelete = useCallback(async id => {
    const token = localStorage.getItem("token");
    try {
      await axios.delete(
        `${process.env.REACT_APP_HOST}/reduction-partners-images/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      refresh();
    } catch (error) {
      showNotification(error.toString());
    }
  }, []);

  const notUndefined = anyValue =>
    typeof anyValue !== "undefined" && typeof anyValue !== "null";

  const options = [
    ...images
      .map((e, i) => ({
        value: i + 1,
        label: i + 1
      }))
      .filter(({ value }) => !currentPositions.includes(value)),
    { value: null, label: "None" }
  ];

  const handleChange = (id, selectedOption) => {
    if (selectedOption) {
      setPositions([
        ...positions.filter(position => id !== position.id),
        { id, position: selectedOption.value }
      ]);
    } else {
      setPositions(positions.filter(position => id !== position.id));
    }
  };

  useEffect(() => {
    setPositions([
      ...images.map(({ id, position }) => ({
        id,
        position
      }))
    ]);
  }, [images]);

  useEffect(() => {
    setCurrentPositions([
      ...new Set([
        ...positions.map(({ position }) => position).filter(notUndefined)
      ])
    ]);
  }, [positions]);

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <table>
        <thead>
          <tr>
            <th width="75%">Image</th>
            <th width="15%">
              <span>position</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {images
            .sort((a, b) => (a.position > b.position ? 1 : -1))
            .map(({ id, url, position }) => (
              <tr key={url}>
                <td>
                  <img
                    src={url}
                    className={classes.image}
                    alt="reduction partner"
                  />
                </td>
                <td>
                  <Select
                    options={options}
                    isClearable
                    defaultValue={
                      position
                        ? { label: position, value: position }
                        : { value: null, label: "None" }
                    }
                    onChange={selectedOption =>
                      handleChange(id, selectedOption)
                    }
                  />
                </td>
                <td>
                  <Button
                    onClick={() => {
                      onDelete(id);
                    }}
                  >
                    delete
                  </Button>{" "}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ImagesTable;
