import React from "react";
import { List, Datagrid, TextField, DateField, ImageField } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  image: { maxWidth: "200px" },
  icon: {
    width: "3rem",
    height: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FBD365",
    borderRadius: 5
  },
  container: { width: "100%" }
});

export const PolicyCategoryList = props => {
  const classes = useStyles();

  return (
    <List {...props}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" />
        <ImageField source="icon" className={classes.icon} />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </Datagrid>
    </List>
  );
};
