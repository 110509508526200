import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

export const InterestCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="label" />
    </SimpleForm>
  </Create>
);
