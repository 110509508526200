import React from "react";

import {
  Show,
  SimpleShowLayout,
  TextField,
  RichTextField,
  DateField,
  NumberField,
  ImageField,
  FunctionField,
} from "react-admin";

const ProjectShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="title" />
        <FunctionField
          label="Price Id"
          render={(record) => (
            <a
              href={`${process.env.REACT_APP_STRIPE_URL}/prices/${record.price_id}`}
            >{`${process.env.REACT_APP_STRIPE_URL}/prices/${record.price_id}`}</a>
          )}
        />
        <NumberField source="price" />
        <TextField source="short_description" />
        <RichTextField source="long_description" />
        <ImageField source="image" />
        <TextField source="sustainability_development_goals" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <TextField source="slug" />
      </SimpleShowLayout>
    </Show>
  );
};

export default ProjectShow;
