import RichTextInput from "ra-input-rich-text";
import React from "react";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
} from "react-admin";

const ProjectCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <NumberInput source="price" />
      <TextInput source="short_description" />
      <RichTextInput source="long_description" initialValue="" />
      <RichTextInput source="further_description" initialValue="" />
      <TextInput source="image" />
      <AutocompleteArrayInput
        source="sustainability_development_goals"
        choices={[
          { id: 1, name: "No Poverty" },
          { id: 2, name: "Zero Hunger" },
          { id: 3, name: "Good Health and Well-being" },
          { id: 4, name: "Quality Education" },
          { id: 5, name: "Gender Equality" },
          { id: 6, name: "Clean Water and Sanitation" },
          { id: 7, name: "Affordable and Clean Energy" },
          { id: 8, name: "Decent Work and Economic Growth" },
          { id: 9, name: "Industry, innovation and infrastructure" },
          { id: 10, name: "Reduced inequalities" },
          { id: 11, name: "Sustainable cities and communities" },
          { id: 12, name: "Responsible consumption and production" },
          { id: 13, name: "Climate action" },
          { id: 14, name: "Life Below Water" },
          { id: 15, name: "Life on land" },
          { id: 16, name: "Peace, justice and strong institutions" },
          { id: 17, name: "Partnerships for the goals" },
        ]}
      ></AutocompleteArrayInput>
      <AutocompleteInput
        source="type"
        choices={[
          { id: "wind_power", name: "Wind Power" },
          { id: "bio_gas", name: "Biogas" },
          { id: "biomass", name: "Biomass" },
          {
            id: "biodiversity_conservation",
            name: "Biodiversity Conservation",
          },
        ]}
      />
      <AutocompleteInput
        source="standard"
        choices={[
          { id: "gold_standard", name: "Gold Standard" },
          { id: "redd", name: "REDD+" },
        ]}
      />
      <AutocompleteInput
        source="state"
        choices={[
          { id: "draft", name: "Draft" },
          { id: "published", name: "Published" },
          { id: "archived", name: "Archived" },
        ]}
      />
    </SimpleForm>
  </Create>
);

export default ProjectCreate;
