import { default as React } from "react";
import {
  BooleanInput,
  Create,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import useConfig from "../../context/config";
import { toSelectOptions } from "../../utils/transformers";

export const OrganisationCreate = (props) => {
  const config = useConfig();

  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        <TextInput source="name" />
        {config && (
          <SelectInput
            source="industry"
            label="Industry"
            validate={required()}
            choices={toSelectOptions(config?.organisationTypes)}
          />
        )}
        <TextInput source="email" />
        <BooleanInput source="free_trial" />
      </SimpleForm>
    </Create>
  );
};
