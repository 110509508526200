import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import {
  ArrayInput,
  BooleanInput,
  Edit,
  FormDataConsumer,
  maxValue,
  minValue,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import { useSelector } from "react-redux";
import AutocompleteInput from "../../components/AutocompleteInput";
import { toSelectOptions } from "../../utils/transformers";

const customStyles = makeStyles({
  input: { display: "flex", width: 256, flexDirection: "column" },
});

const PERIODS = [
  { name: "Quarterly", id: "quarter" },
  { name: "Bi-Annually", id: "bi-annual" },
  { name: "Annually", id: "annual" },
];

const DISTANCE_METRICS = [
  { name: "Kilometres", id: "km" },
  { name: "Miles", id: "miles" },
];

const WASTE_METRICS = [
  { name: "kg", id: "kg" },
  { name: "Bin Bags", id: "bin_bags" },
];

export const WASTE_PERIODS = [
  { name: "Weekly", id: "week" },
  { name: "Monthly", id: "month" },
  ...PERIODS,
];
export const OfficeMeasurementEdit = (props) => {
  const [config, setConfig] = useState(null);
  const [countries, setCountries] = useState([]);
  const record = useSelector(
    (state) =>
      state.admin.resources[props.resource]
        ? state.admin.resources[props.resource].data[props.id]
        : null,
    [props.resource, props.id]
  );
  const classes = customStyles();

  const getCountries = async () => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await Axios.get(
        `${process.env.REACT_APP_HOST}/places/country-codes`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCountries(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getConfig = async () => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await Axios.get(`${process.env.REACT_APP_HOST}/config`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setConfig(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCountries();
    getConfig();
  }, []);

  return (
    <Edit {...props}>
      <SimpleForm redirect="show">
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <>
              <ReferenceInput
                source="details.organisation_id"
                reference="organisations"
                label="Organisation"
                className={classes.input}
                validate={required()}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <ReferenceInput
                source="measurement_id"
                reference="measurements"
                validate={required()}
                className={classes.input}
                filter={{ organisation_id: record?.details?.organisation_id }}
              >
                <SelectInput
                  optionText={(record) =>
                    `${record.month + 1}/${record.year} - ${
                      record.organisation_id
                    }`
                  }
                />
              </ReferenceInput>
              <ReferenceInput
                source="office_id"
                reference="offices"
                validate={required()}
                filter={{ organisation_id: record?.details?.organisation_id }}
                className={classes.input}
              >
                <SelectInput optionText="name" validate={required()} />
              </ReferenceInput>

              <SelectInput
                source="details.country"
                label="Country code"
                validate={required()}
                className={classes.input}
                choices={toSelectOptions(countries)}
              />
              <NumberInput
                source="details.number_of_staff"
                label="Number of staff"
                className={classes.input}
                validate={[required(), minValue(0)]}
              />
              <NumberInput
                source="details.staff_working_from_home_percentage"
                label="Staff working from home percentage"
                className={classes.input}
                validate={[required(), minValue(0), maxValue(100)]}
              />
              <BooleanInput
                source="details.fully_remote"
                label="Fully Remote"
                className={classes.input}
              />

              <ArrayInput source="utilities">
                <SimpleFormIterator>
                  <SelectInput
                    source="type"
                    label="Type"
                    validate={required()}
                    className={classes.input}
                    choices={[
                      { id: "gas", name: "Gas" },
                      { id: "electricity", name: "Electricity" },
                      { id: "water", name: "Water" },
                    ]}
                  />
                  <SelectInput
                    source="known"
                    label="Known"
                    validate={required()}
                    className={classes.input}
                    choices={[
                      { name: "Yes", id: "yes" },
                      { name: "No", id: "no" },
                      { name: "Not Used", id: "not_used" },
                    ]}
                  />
                  <NumberInput
                    source="value"
                    label="Value"
                    validate={[required(), minValue(0)]}
                    className={classes.input}
                  />

                  <SelectInput
                    source="unit"
                    label="Unit"
                    validate={required()}
                    className={classes.input}
                    choices={[
                      { name: "kWh", id: "kwh" },
                      { name: "m3", id: "m3" },
                      { name: "GBP", id: "gbp" },
                    ]}
                  />
                </SimpleFormIterator>
              </ArrayInput>
              <ArrayInput source="waste">
                <SimpleFormIterator>
                  <SelectInput
                    source="type"
                    label="Type"
                    validate={required()}
                    className={classes.input}
                    choices={[
                      { name: "Landfill", id: "landfill" },
                      { name: "Recycling", id: "recycling" },
                    ].filter(({ id }) => formData?.waste)}
                  />
                  <SelectInput
                    source="known"
                    label="Known"
                    validate={required()}
                    className={classes.input}
                    choices={[
                      { name: "Yes", id: "yes" },
                      { name: "No", id: "no" },
                      { name: "Not Used", id: "not_used" },
                    ]}
                  />

                  <NumberInput
                    source="value"
                    label="Value"
                    validate={[required(), minValue(0)]}
                    className={classes.input}
                  />
                  <SelectInput
                    source="unit"
                    label="Unit"
                    choices={WASTE_METRICS}
                    validate={required()}
                    className={classes.input}
                  />
                </SimpleFormIterator>
              </ArrayInput>

              <ArrayInput source="employee_commutes">
                <SimpleFormIterator>
                  <TextInput
                    source="name"
                    label="Name"
                    validate={required()}
                    className={classes.input}
                  />
                  <NumberInput
                    source="value"
                    label="Value"
                    validate={[required(), minValue(0)]}
                    className={classes.input}
                  />
                  {config && (
                    <SelectInput
                      source="mode"
                      label="Mode"
                      validate={required()}
                      className={classes.input}
                      choices={toSelectOptions(
                        config?.employeeCommutesModesOfTransport
                      )}
                    />
                  )}
                  <SelectInput
                    source="unit"
                    label="Unit"
                    validate={required()}
                    className={classes.input}
                    choices={DISTANCE_METRICS}
                  />
                </SimpleFormIterator>
              </ArrayInput>

              <ArrayInput source="business_travel">
                <SimpleFormIterator>
                  <AutocompleteInput
                    source="to"
                    label="To"
                    className={classes.input}
                    validate={required()}
                  />
                  <AutocompleteInput
                    source="from"
                    label="From"
                    className={classes.input}
                    validate={required()}
                  />
                  {config && (
                    <SelectInput
                      source="mode"
                      label="Mode"
                      className={classes.input}
                      validate={required()}
                      choices={toSelectOptions(
                        config?.businessTravelModesOfTransport
                      )}
                    />
                  )}
                  <NumberInput
                    source="passengers"
                    label="Passengers"
                    className={classes.input}
                    validate={[required(), minValue(0)]}
                  />
                  <BooleanInput source="round_trip" label="Round Trip?" />
                </SimpleFormIterator>
              </ArrayInput>

              <ArrayInput source="vehicle_usage">
                <SimpleFormIterator>
                  <TextInput
                    source="registration_number"
                    label="Registration Number"
                    validate={required()}
                    className={classes.input}
                  />
                  <NumberInput
                    source="value"
                    label="Value"
                    validate={[required(), minValue(0)]}
                    className={classes.input}
                  />
                  {config && (
                    <SelectInput
                      source="mode"
                      label="Mode"
                      validate={required()}
                      className={classes.input}
                      choices={toSelectOptions(
                        config?.vehicleUsageModesOfTransport
                      )}
                    />
                  )}
                  <SelectInput
                    source="unit"
                    label="Unit"
                    validate={required()}
                    className={classes.input}
                    choices={DISTANCE_METRICS}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
