import React, { useEffect, useState, useCallback } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  ReferenceInput,
  AutocompleteArrayInput,
  AutocompleteInput
} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import RichTextInput from "ra-input-rich-text";
import axios from "axios";
import Button from "@material-ui/core/Button";
import ReductionPartnerImageUpload from "./ReductionPartnerImageUpload";

export const ReductionPartnerEdit = props => {
  const [reductionTags, setReductionTags] = useState([]);
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  const getReductionTags = async () => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.get(
        `${process.env.REACT_APP_HOST}/reduction-tags`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setReductionTags(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getReductionTags();
  }, []);

  const handleNewClick = useCallback(
    event => {
      event.preventDefault();
      setShowCreateDialog(true);
    },
    [setShowCreateDialog]
  );

  const handleCloseCreate = useCallback(() => {
    setShowCreateDialog(false);
  }, [setShowCreateDialog]);

  return (
    <>
      <Edit {...props}>
        <SimpleForm>
          <TextInput source="name" />

          <Button data-testid="button-add-post" onClick={handleNewClick}>
            View Images
          </Button>
          <TextInput source="link" />
          <TextInput source="discount_code" />
          <TextInput source="discount" />
          <TextInput source="summary" />
          <RichTextInput source="benefits" />
          <RichTextInput source="description" />
          <NumberInput source="price" />
          <TextInput source="price_suffix" />
          <ReferenceInput
            source="reduction_category_id"
            reference="reduction-categories"
          >
            <SelectInput optionText="label" />
          </ReferenceInput>
          <AutocompleteInput
            source="state"
            choices={[
              { id: "draft", name: "Draft" },
              { id: "published", name: "Published" },
              { id: "archived", name: "Archived" }
            ]}
          />
          {reductionTags.length && (
            <AutocompleteArrayInput
              source="reduction_tags_ids"
              choices={reductionTags}
              optionText="label"
            ></AutocompleteArrayInput>
          )}
        </SimpleForm>
      </Edit>
      <Dialog
        data-testid="dialog-add-post"
        fullWidth
        open={showCreateDialog}
        onClose={handleCloseCreate}
      >
        <DialogTitle>Upload Images</DialogTitle>
        <DialogContent>
          <ReductionPartnerImageUpload
            onCancel={handleCloseCreate}
            onSave={handleCloseCreate}
            id={props.id}
            resource={props.resource}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
