import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput
} from "react-admin";

export const PolicyEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="description" />
      <ReferenceInput source="policy_category_id" reference="policy-categories">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
