import axios from "axios";

const authProvider = {
  login: async ({ username, password }) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_HOST}/login`, {
        email: username,
        password
      });
      const { token } = data;
      localStorage.setItem("token", token);
    } catch (error) {
      console.log(error.message);
    }
  },
  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
  getPermissions: params => Promise.resolve()
};

export default authProvider;
