import Axios from "axios";
import React, { useEffect, useState } from "react";
import {
  BooleanInput,
  Edit,
  minValue,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { toSelectOptions } from "../../utils/transformers";

export const OfficeEdit = (props) => {
  const [countries, setCountries] = useState([]);

  const getCountries = async () => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await Axios.get(
        `${process.env.REACT_APP_HOST}/places/country-codes`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCountries(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} fullWidth />
        <SelectInput source="country" choices={toSelectOptions(countries)} />
        <NumberInput
          source="size"
          type="number"
          validate={[required(), minValue(0)]}
          fullWidth
        />
        <SelectInput
          source="unit"
          choices={[
            { name: "m²", id: "m2" },
            { name: "ft²", id: "sqft" },
          ]}
        />
        <BooleanInput source="fully_remote" />
        <ReferenceInput source="organisation_id" reference="organisations">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
