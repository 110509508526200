import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  EmailField,
  DateField
} from "react-admin";

const InternalUserShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <EmailField source="email" />
      <ReferenceField source="role_id" reference="roles">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

export default InternalUserShow;
