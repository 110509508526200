import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import CreateTwoToneIcon from "@material-ui/icons/CreateTwoTone";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import axios from "axios";
import React from "react";
import {
  Button,
  ChipField,
  DateField,
  EditButton,
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useRefresh,
} from "react-admin";
import { Link } from "react-router-dom";

const AddNewMeasurementResultButton = ({ record }) => (
  <Button
    component={Link}
    to={`/measurement-results/create?measurement_id=${record.id}`}
    label="Add a Result"
  >
    <ChatBubbleIcon />
  </Button>
);

const EditMeasurementResultButton = ({ record }) => (
  <Button
    component={Link}
    to={`/measurement-results/${record.result.id}/edit?measurement_id=${record.id}`}
    label=" Edit Result"
  >
    <ChatBubbleIcon />
  </Button>
);

const CreateSpreadsheetButton = ({ record }) => {
  const refresh = useRefresh();

  const createSpreadSheet = async (record) => {
    const token = localStorage.getItem("token");

    const { data } = await axios.post(
      `${process.env.REACT_APP_HOST}/measurements/${record.id}/spreadsheet`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    window.open(data.link);
    refresh();
  };

  return (
    <Button
      label="Create Spreadsheet"
      onClick={async () => await createSpreadSheet(record)}
    >
      <CreateTwoToneIcon />
    </Button>
  );
};

const OpenSpreadsheetButton = ({ record }) => (
  <Button
    onClick={() =>
      window.open(
        `https://docs.google.com/spreadsheets/d/${record.spreadsheet_id}`
      )
    }
    label="Open Spreadsheet"
  >
    <OpenInNewIcon />
  </Button>
);

const DeleteSpreadsheetButton = ({ record }) => {
  const refresh = useRefresh();

  const deleteSpreadSheet = async (record) => {
    const token = localStorage.getItem("token");

    await axios.delete(
      `${process.env.REACT_APP_HOST}/measurements/${record.id}/spreadsheet`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    refresh();
  };

  return (
    <Button
      label="Delete Spreadsheet"
      onClick={async () => await deleteSpreadSheet(record)}
    >
      <DeleteTwoToneIcon />
    </Button>
  );
};

const PostShowActions = ({ basePath, data }) => {
  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

export const MeasurementShow = (props) => {
  return (
    <>
      <Show actions={<PostShowActions />} {...props}>
        <TabbedShowLayout>
          <Tab label="Summary">
            <FunctionField
              label="Time"
              render={(record) => `${record.month + 1}/${record.year}`}
            />{" "}
            <NumberField source="time_period" />
            <ReferenceField source="organisation_id" reference="organisations">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceArrayField
              reference="office-measurements"
              source="office_measurements_ids"
              label="Office Measurements"
            >
              <SingleFieldList>
                <ChipField source="details.name" />
              </SingleFieldList>
            </ReferenceArrayField>
            <DateField source="created_at" />
            <DateField source="updated_at" />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};
