import React from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  List,
  ReferenceField,
  TextField,
} from "react-admin";

const UserList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <EmailField source="email" />
      <ReferenceField source="organisation_id" reference="organisations">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <TextField source="projects_funded" />
      <BooleanField source="terms_accepted" />
      <BooleanField source="is_admin" />
      <DateField source="deleted_at" />
    </Datagrid>
  </List>
);

export default UserList;
