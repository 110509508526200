import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
} from "react-admin";

export const ReductionStrategyList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField
        source="organisation_id"
        reference="organisations"
        label="Organisation"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="pdf_url" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);
