import React, { useEffect, useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  AutocompleteArrayInput,
  AutocompleteInput
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import axios from "axios";

export const ReductionPartnerCreate = props => {
  const [reductionTags, setReductionTags] = useState([]);

  const getReductionTags = async () => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.get(
        `${process.env.REACT_APP_HOST}/reduction-tags`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setReductionTags(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getReductionTags();
  }, []);
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="link" />
        <TextInput source="discount_code" />
        <TextInput source="discount" />
        <TextInput source="summary" />
        <RichTextInput source="benefits" />
        <RichTextInput source="description" />
        <NumberInput source="price" />
        <TextInput source="price_suffix" />
        <ReferenceInput
          source="reduction_category_id"
          reference="reduction-categories"
        >
          <SelectInput optionText="label" />
        </ReferenceInput>
        <AutocompleteInput
          source="state"
          choices={[
            { id: "draft", name: "Draft" },
            { id: "published", name: "Published" },
            { id: "archived", name: "Archived" }
          ]}
        />
        {reductionTags.length && (
          <AutocompleteArrayInput
            source="reduction_tags_ids"
            choices={reductionTags}
            optionText="label"
          ></AutocompleteArrayInput>
        )}
      </SimpleForm>
    </Create>
  );
};
