import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  ReferenceInput,
  SelectInput
} from "react-admin";

const InternalUserEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <TextInput source="email" />
      <ReferenceInput source="role_id" reference="roles">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default InternalUserEdit;
