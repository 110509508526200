import React from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  TextField,
} from "react-admin";

export const OfficeMeasurementList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField source="measurement_id" reference="measurements">
        <FunctionField
          label="Name"
          render={(record) => `${record.month + 1}/${record.year}`}
        />
      </ReferenceField>
      <ReferenceField source="office_id" reference="offices">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="details.country" label="Country code" />
      <BooleanField source="details.fully_remote" label="Fully Remote" />
      <FunctionField
        label="Size"
        render={(record) => `${record.details.size}${record.details.unit}`}
      />
      <NumberField source="details.number_of_staff" label="Number of staff" />
      <NumberField
        source="details.staff_working_from_home_percentage"
        label="Staff working from home percentage"
      />
      <ReferenceField
        source="details.organisation_id"
        reference="organisations"
        label="Organisation"
      >
        <TextField source="name" />
      </ReferenceField>

      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);
