import React, { useCallback, useState } from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ReductionCategoryImageUpload from "./ReductionCategoryImageUpload";

export const ReductionCategoryEdit = props => {
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const handleNewClick = useCallback(
    event => {
      event.preventDefault();
      setShowCreateDialog(true);
    },
    [setShowCreateDialog]
  );

  const handleCloseCreate = useCallback(() => {
    setShowCreateDialog(false);
  }, [setShowCreateDialog]);

  return (
    <>
      <Edit {...props}>
        <SimpleForm>
          <TextInput source="name" />
          <TextInput source="label" />
          <Button data-testid="button-add-post" onClick={handleNewClick}>
            Update Icon
          </Button>
        </SimpleForm>
      </Edit>
      <Dialog
        data-testid="dialog-add-post"
        fullWidth
        open={showCreateDialog}
        onClose={handleCloseCreate}
      >
        <DialogTitle>Upload Images</DialogTitle>
        <DialogContent>
          <ReductionCategoryImageUpload
            onCancel={handleCloseCreate}
            onSave={handleCloseCreate}
            id={props.id}
            resource={props.resource}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
