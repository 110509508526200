import Axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";

const ConfigContext = createContext({});

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(null);

  const getServerConfig = async () => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await Axios.get(`${process.env.REACT_APP_HOST}/config`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setConfig(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getServerConfig();
  }, []);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export default function useConfig() {
  return useContext(ConfigContext);
}
