import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  EmailField,
  ReferenceField
} from "react-admin";

const InternalUserList = props => (
  <List {...props}>
    <Datagrid rowClick="show">
      <NumberField source="id" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <EmailField source="email" />
      <ReferenceField source="role_id" reference="roles">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);

export default InternalUserList;
